.Level {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.Level .Form-Container {
    max-width: 400px;
    background: white;
    border-radius: 30px;
    border-left: 5px solid var(--primary);
    border-bottom: 7px solid var(--primary);
    box-shadow: 4px 4px 8px 0 rgba(0,0,0,.2);
    padding: 10px;
}

.Level .Form-Container form {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    gap: 10px;
    font-size: 3em;
}

.Level .Form-Container form h2 {
    margin: 0;
    font-size: 1em;
    padding: 10px;
    white-space: nowrap;
    color: var(--secondary);
}

.Level .Form-Container form input {
    padding: 10px;
    width: 90%;
    border-radius: 30px;
    border-top: 1px solid var(--secondary);
    border-right: 1px solid var(--secondary);
    border-left: 5px solid var(--secondary);
    border-bottom: 7px solid var(--secondary);
    font-weight: bold;
}

.Level .Form-Container form button {
    font-size: 2rem;
    border-radius: 30px;
    background-color: var(--primary);
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 0;
    border-left: 5px solid var(--secondary);
    border-bottom: 7px solid var(--secondary);
    cursor: pointer;
}

.Level .Form-Container form button:hover {
    border-left: 1px solid var(--secondary);
    border-bottom: 3px solid var(--secondary);
}
