.level4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  text-align: center;
}

.level4 h3 {
  width: fit-content;
  margin: auto;
  max-width: 80%;
  line-height: 1em;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--secondary);
}

.level4 p {
  line-height: 1.5em;
}

.level4 .Form-Container {
  width: 30ch;
  min-width: fit-content;
  max-width: 400px;
  background: white;
  border-radius: 30px;
  border-left: 5px solid var(--primary);
  border-bottom: 7px solid var(--primary);
}
.level4 .Form-Container form {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 10px;
  font-size: 3em;
}

.level4 .Form-Container form h2 {
  margin: 0;
  font-size: 1em;
  padding: 10px;
  white-space: nowrap;
  color: var(--secondary);
}

.level4 .Form-Container form input {
  padding: 10px;
  width: 90%;
  border-radius: 30px;
  border-top: 1px solid var(--secondary);
  border-right: 1px solid var(--secondary);
  border-left: 5px solid var(--secondary);
  border-bottom: 7px solid var(--secondary);
  font-weight: bold;
}

.level4 .Form-Container form button {
  font-size: 1em;
  border-radius: 30px;
  background-color: var(--primary);
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0;
  border-left: 5px solid var(--secondary);
  border-bottom: 7px solid var(--secondary);
  cursor: pointer;
}

.level4 .Form-Container form button:hover {
  border-left: 1px solid var(--secondary);
  border-bottom: 3px solid var(--secondary);
}

.dollarContainer {
  position: relative;
}

.dollarContainer input {
  padding-left: 2ch;
  font-size: 1rem;
}

.dollarSign {
  position: absolute;
  top: 50%;
  left: 0.5ch;
  transform: translateY(-50%);
  pointer-events: none;
}

.shimmer-text {
  font-size: 24px;
  font-weight: bold;
  color: #FF4F7E;
}

/*
.shimmer-text {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(90deg, #FF4F7E 25%, #FF6C49 50%, #FF00FF 75%);
  background-size: 200% 100%;
  color: transparent;
  background-clip: text;
  animation: shimmer 2s linear infinite;
}
*/

/* Create the shimmer animation */
/*
@keyframes shimmer {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}
*/
